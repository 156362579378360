/* eslint-disable react/prop-types */
import React from 'react';

import Button from 'components/shared/button';
import ChevronRight from 'icons/chevron-right.inline.svg';

const DocNavLinks = ({ previousLink, nextLink }) => (
  <div className="mt-16 w-full flex">
    {previousLink && (
      <Button
        to={previousLink.slug}
        size="sm"
        theme="primary-black-outline"
        className="mr-auto px-5"
      >
        <ChevronRight className="mr-2.5 transform rotate-180" />
        {previousLink.sidebarLabel}
      </Button>
    )}
    {nextLink && (
      <Button to={nextLink.slug} size="sm" theme="primary-black-outline" className="ml-auto px-5">
        {nextLink.sidebarLabel}
        <ChevronRight className="ml-2.5" />
      </Button>
    )}
  </div>
);

export default DocNavLinks;
