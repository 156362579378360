/* eslint-disable react/prop-types */
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import PropTypes from 'prop-types';
import React from 'react';

import AnchorHeading from 'components/shared/anchor-heading';
import DocCodeBlock from 'components/shared/doc-code-block';
import Heading from 'components/shared/heading';
import Warning from 'components/shared/warning';

const Wrapper = ({ children }) => (
  <div className="prose prose-lg !max-w-full !mt-6 md:!mt-5 doc-content">{children}</div>
);

const components = {
  wrapper: Wrapper,
  code: (props) => <DocCodeBlock {...props} />,
  pre: (props) => <div {...props} />,
  blockquote: (props) => <Warning {...props} />,
  h2: AnchorHeading('h2'),
  h3: AnchorHeading('h3'),
};

const DocContent = ({ title, content }) => (
  <article className="relative">
    <div className="relative flex flex-col">
      <Heading size="lg" tag="h1" theme="primary-black">
        {title}
      </Heading>
      <MDXProvider components={components}>
        <MDXRenderer>{content}</MDXRenderer>
      </MDXProvider>
    </div>
  </article>
);

DocContent.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default DocContent;
