// eslint-disable-next-line import/prefer-default-export
const sidebarData = {
  'Hosted Service': [
    'start',
    'works',
    'proxying',
    'graphql-api',
    'best-practices',
    'terminology',
    'token',
    'worker-settings',
    'load-balancers',
    'whitelist',
    'versions',
  ],
  Docker: ['docker-quickstart', 'docker', 'webhooks', 'extending-docker'],
  APIs: [
    'api',
    'content',
    'download',
    'function',
    'pdf',
    'screencast',
    'screenshot',
    'scrape',
    'stats',
    'workspace',
    'sessions',
    'metrics',
    'pressure',
    'kill',
    'config',
  ],
  Libraries: [
    'puppeteer-library',
    'playwright',
    'selenium-library',
    'capybara',
    'dotnet',
    'java',
    'pythonpw',
    'python',
    'pyppeteer',
    'scrapy',
    'go',
    'php',
  ],
  Recipes: ['bypass-bot-detection','downloading-files', 'using-a-proxy', 'watching-sessions', 'setting-a-user-agent'],
  Options: ['chrome-flags', 'api-get'],
};

module.exports = { sidebarData };
