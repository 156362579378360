/* eslint-disable react/prop-types */
import classNames from 'classnames';
import { Link, navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useLayoutEffect } from 'react';

import Container from 'components/shared/container';
import CTA from 'components/shared/cta';
import Footer from 'components/shared/footer';
import Header from 'components/shared/header';
import CustomLink from 'components/shared/link';
import MobileMenu from 'components/shared/mobile-menu';
import SEO from 'components/shared/seo';
import { defaultCTA } from 'constants/component-data';
import ChevronRight from 'icons/chevron-right.inline.svg';

const MobileNavMenu = ({ sidebar, currentSlug }) => {
  const [value, setValue] = useState(null);
  useLayoutEffect(() => {
    const sectionIndex = sidebar.findIndex(
      (item) => item.children.findIndex((child) => child.slug === currentSlug) !== -1
    );
    const itemIndex = sidebar[sectionIndex].children.findIndex(
      (child) => child.slug === currentSlug
    );
    setValue({
      label: sidebar[sectionIndex].children[itemIndex].sidebarLabel,
      value: sidebar[sectionIndex].children[itemIndex].slug,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlug]);

  return (
    <div className="relative w-full">
      <select
        className="select w-full"
        // value={value}
        onChange={({ target }) => {
          const val = target.value;
          if (!val) return;
          navigate(`/docs/${val}`);
        }}
      >
        <option value={false} disabled>
          Choose section
        </option>
        {sidebar &&
          sidebar.map(({ sidebarLabel, children }) => (
            <optgroup label={sidebarLabel} key={sidebarLabel}>
              {children.map(({ slug, sidebarLabel: childSidebarLabel }) => (
                <option
                  label={childSidebarLabel}
                  value={slug}
                  key={childSidebarLabel}
                  selected={value && slug === value.value}
                >
                  {childSidebarLabel}
                </option>
              ))}
            </optgroup>
          ))}
      </select>
      <ChevronRight className="absolute right-4 top-1/2 -translate-y-1/2 rotate-90" />
    </div>
  );
};

const Sidebar = ({ sidebar, currentSlug }) => {
  const initialState = {};
  sidebar.forEach(({ title }) => (initialState[title] = false));
  const sectionIndex = sidebar.findIndex(
    (item) => item.children.find((child) => child.slug === currentSlug) !== undefined
  );

  initialState[sidebar[sectionIndex].title] = true;

  const [sidebarState, setSidebarState] = useState(initialState);

  const handleItemClick = (title) => {
    const newState = { ...sidebarState };
    newState[title] = !newState[title];
    setSidebarState(newState);
  };

  return (
    <>
      {sidebar.map(({ title, sidebarLabel, children }, index) => (
        <div key={index}>
          <div
            className="flex items-center pt-3 pb-3"
            role="button"
            tabIndex="0"
            onClick={() => handleItemClick(title)}
            onKeyDown={() => handleItemClick(title)}
          >
            <ChevronRight
              className={classNames('mr-2 transition-transform duration-500', {
                'transform rotate-90': sidebarState[title],
              })}
            />
            <span className="text-lg leading-none font-semibold">{sidebarLabel}</span>
          </div>
          {sidebarState[title] && (
            <div className="flex flex-col space-y-1 py-2 pl-4">
              {children.map((child, childIndex) => (
                <Link
                  key={`${index}-${childIndex}`}
                  to={`/docs/${child.slug}`}
                  className={classNames('text-md leading-none py-2 hover:text-secondary-pink', {
                    'font-semibold text-secondary-pink': currentSlug === child.slug,
                  })}
                >
                  {child.sidebarLabel}
                </Link>
              ))}
            </div>
          )}
        </div>
      ))}
    </>
  );
};

const DocLayout = ({ seo, currentSlug, sidebar, children }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const handleHeaderBurgerClick = () => setIsMobileMenuOpen(true);
  const handleMobileMenuOverlayOrCloseClick = () => setIsMobileMenuOpen(false);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.querySelector('html').style = 'overflow: hidden';
      document.querySelector('body').style = 'overflow: hidden';
    } else {
      document.querySelector('html').style = '';
      document.querySelector('body').style = '';
    }
  }, [isMobileMenuOpen]);

  return (
    <>
      {seo && <SEO {...seo} />}
      <div className="flex flex-col min-h-screen">
        <div className="w-full flex justify-center bg-secondary-pink-hover font-semibold py-2 fixed z-50 md:!text-center md:px-2">
          <span>
            Note: These docs cover v1 of Browserless, which is used on our shared cloud. For our v2
            docs, please
            <CustomLink
              className="text-white ml-1"
              to="https://github.com/browserless/browserless/blob/main/MIGRATION-2.0.md#browserless-20-migration-guide"
              target="_blank"
            >
              click here
            </CustomLink>
          </span>
        </div>
        <Header theme="primary-white" className="mt-10" onBurgerClick={handleHeaderBurgerClick} />
        <Container className="flex flex-grow h-full mb-auto lg:flex-col">
          <div className="w-[313px] flex-shrink-0 mt-24 xl:w-[256px] lg:hidden">
            <Sidebar sidebar={sidebar} currentSlug={currentSlug} />
          </div>
          <div className="hidden lg:block w-full">
            <MobileNavMenu sidebar={sidebar} currentSlug={currentSlug} />
          </div>
          <main className="w-[calc(100%-313px)] mt-20 xl:w-[calc(100%-256px)] lg:w-full lg:mt-14 md:mt-10">
            {children}
          </main>
        </Container>
        <CTA {...defaultCTA} />
        <Footer />
        <MobileMenu
          isOpen={isMobileMenuOpen}
          onOverlayOrCloseClick={handleMobileMenuOverlayOrCloseClick}
        />
      </div>
    </>
  );
};

DocLayout.propTypes = {
  seo: PropTypes.object,
  pageContext: PropTypes.object,
  children: PropTypes.node.isRequired,
};

DocLayout.defaultProps = {
  seo: null,
  pageContext: null,
};

export default DocLayout;
