/* eslint-disable react/prop-types */
import React from 'react';

import Code from 'components/shared/code-block';

const DEFAULT_DOC_LANGUAGE = 'bash';

const DocCodeBlock = ({ className, children, ...props }) => {
  const match = /language-(\w+)/.exec(className || '');
  const language = match ? match[1] : DEFAULT_DOC_LANGUAGE;
  const code = children.trim();
  return <Code language={language} code={code} {...props} />;
};

export default DocCodeBlock;
