/* eslint-disable react/prop-types */
import React from 'react';

import InfoIcon from './images/info.inline.svg';

const Warning = ({ children, ...props }) => (
  <blockquote
    className="p-4 flex items-start bg-[#fef8dd] warning-content rounded !not-italic !border-l-0"
    {...props}
  >
    <InfoIcon className="mr-4 flex-shrink-0" />
    {children}
  </blockquote>
);

export default Warning;
